'use strict';
/**
 * Displaying the extra menu
 */

app.extraMenu = {
  _vars: {},

  /**
   * Make base initialization with parameters
   * @param params {object} Parameters
   * selector - selector for item that will expand / collapse the menu
   * side - side with which the menu will appear: left or right
   */
  init: function init(params) {
    var self = this;
    params = params || {};

    if (!params.selector) {
      return;
    }

    var side = 'left';

    if (!params.side || params.side !== 'left' && params.side !== 'right') {
      params.side = side;
    }

    params.saveState = typeof params.saveState !== 'undefined' ? params.saveState : true;

    if (app.getLData('uduExMenu').isOpen === 'Y' && params.saveState) {
      self.switchExtraMenu(params);
    }

    self.eventsListener(params);
  },

  /**
   * Reinitialization after adding new elements (out of scope of DOM)
   */
  reinit: function reinit() {
    app.extraMenu.interceptionOfEventsOnclick();
    app.extraMenu.addID();
    app.assignNumberControl();
  },

  /**
   * Event Listener for Extra Menu
   * @param params {object} Parameters
   */
  eventsListener: function eventsListener(params) {
    var self = this;
    $(document).on('click', params.selector, function () {
      self.switchExtraMenu(params);
    });
    $(document).on('click', '.extra-menu.opened .extra-links li a', function () {
      $('.extra-menu > div').slideToggle(300, function () {
        $('.extra-menu').fadeOut(300, function () {
          $('.extra-menu').remove();
          $('body').removeClass('extra-menu-active');
          self.changeIsActiveClassHamb(params);
        });
      });
    });
    app.events.addListener('udu-PROD-FROM-LIST-ADDED', function (data) {
      if (checkCartSectionInExtraMenu()) {
        self.addProduct(data.data.productId);
      }
    });
    app.events.addListener('udu-ITEM-DELETED-FROM-CART', function (data) {
      if (checkCartSectionInExtraMenu()) {
        self.deleteProduct(data.data.id, 'popup');
      }
    });
    app.events.addListener('udu_MULT-PROD_ADDED-BEFORE-GO-CART', function () {
      if (checkCartSectionInExtraMenu()) {
        self.getURL('cart');
      }
    });

    function checkCartSectionInExtraMenu() {
      if ($('.extra-menu .car-row').length > 0 || $('.extra-menu .udu__empty-cart-message').length) {
        return true;
      } else {
        return false;
      }
    }
  },

  /**
   * Extra menu display switching
   * @param params {object} Parameters
   */
  switchExtraMenu: function switchExtraMenu(params) {
    var self = this;
    var $extraMenu = $('.extra-menu');
    self.changeIsActiveClassHamb(params);
    $('body').toggleClass('extra-menu-active');

    if ($extraMenu.length === 0) {
      self.createExtraMenu(params);
    } else {
      if ($extraMenu.hasClass('opened')) {
        $extraMenu.removeClass('opened');
        app.setLData('uduExMenu', {
          isOpen: 'N'
        });
      } else {
        $extraMenu.addClass('opened');
        app.setLData('uduExMenu', {
          isOpen: 'Y'
        });
      }
    }

    self.deleteQntMessageError();
  },
  changeIsActiveClassHamb: function changeIsActiveClassHamb(params) {
    var extraMenuHamb = '.extramenu-hamb';

    if (params.selector === extraMenuHamb) {
      $(params.selector).toggleClass('is-active');
    } else {
      $(extraMenuHamb).toggleClass('is-active');
      $(params.selector).toggleClass('is-active');
    }
  },

  /**
   * Creating the extra menu block for the first time
   * @param params {object} Parameters
   */
  createExtraMenu: function createExtraMenu(params) {
    var $top = $('._ > .top');
    var htmlMenu = '<div class="extra-menu udu__scroller-1 ' + params.side + '"></div>';
    $top.before(htmlMenu);
    app.events.trigger('udu-EXTRA-MENU-CREATED', {
      $item: $('.extra-menu')
    });
    setTimeout(function () {
      $('.extra-menu').toggleClass('opened').addClass('loading');
    }, 300);
    app.extraMenu.getInfo(function () {
      var $extraMenu = $('.extra-menu');
      app.extraMenu.reinit();
      app.setLData('uduExMenu', {
        isOpen: 'Y'
      });
      $extraMenu.removeClass('loading'); //In case of scrolling for search section

      var $search = $('.extra-search-form');

      if ($search.length) {
        var $searchPos = $search.offset();
        $extraMenu.scroll(function () {
          var scroll = $extraMenu.scrollTop();

          if (scroll > $searchPos.top) {
            $search.addClass('fix');
          } else {
            $search.removeClass('fix');
          }
        });
      }
    });
  },

  /**
   * Changing the default event functions for recalculating and deleting an product
   */
  interceptionOfEventsOnclick: function interceptionOfEventsOnclick() {
    var $removeBtn = $('.extra-menu .remove');
    $removeBtn.each(function () {
      var idProduct = $(this).prev('[name="cartItemKey"]').val();
      var $delForm = $(this).parent('form.del-form');
      var $recForm = $delForm.prev('form'); //define event Delete

      $delForm.removeAttr('action');
      $(this).attr('onclick', "return app.extraMenu.deleteProduct(".concat(idProduct, ");")); //define event Recalculate

      $recForm.removeAttr('action').attr('onsubmit', "return app.extraMenu.recalculateProduct(".concat(idProduct, ");"));
    });
  },

  /**
   * Assigning an identifier to each item product in the cart, recalculate button
   */
  addID: function addID() {
    var $removeBtn = $('.extra-menu .remove');
    var saveID = {};
    var key = 0;
    $removeBtn.each(function () {
      var idProduct = $(this).prev('[name="cartItemKey"]').val();
      $(this).parents('.car-row').addClass("product-id-".concat(idProduct));
      $(this).parent('form.del-form').prev('form').children('[name="recalculate"]').addClass("anim-id-".concat(idProduct));
      saveID[key] = idProduct;
      key++;
    });

    if ($('.bg-payment').length > 0) {
      key = 0;
      $('.bg-payment .div-border .car-row').each(function () {
        $(this).addClass("product-id-".concat(saveID[key]));
        key++;
      });
    }
  },

  /**
   * Adding a product to an Extra Menu
   * @param idHTML {string} the last .html value of the product page link
   */
  addProduct: function addProduct(idHTML) {
    $.get(app.ajaxProcessURLs.process, {
      op: 'cart-table'
    }, function (data) {
      var $parse = $($.parseHTML(data));
      var $product = $parse.find('a[href$=\'' + idHTML + '\']');
      var $cartTable = $('.extra-menu .list tbody');

      if ($cartTable.length > 0) {
        if ($cartTable.find('a[href$=\'' + idHTML + '\']').length === 0) {
          $('.extra-menu .list tbody tr:not(.car-row)').before($product.closest('.car-row'));
          app.extraMenu.generalUpdate(data);
          app.extraMenu.reinit();
        } else {
          return;
        }
      } else {
        app.extraMenu.getInfo(function () {
          app.extraMenu.reinit();
        });
      }
    });
  },

  /**
   * Removing a product from extra menu and basket in general
   * @param id {number} ID of the product to be removed from the cart
   * @param place {string} If the deletion was done in the popup cart
   */
  deleteProduct: function deleteProduct(id, place) {
    var $productDel = $(".product-id-".concat(id));

    if (place === 'popup') {
      $productDel.fadeOut(function () {
        $('.udu__td-bottom-subtotal .money').addClass('load');
        app.getInfo('cart-table', function (data) {
          if ($('.extramenu-container .car-row').length !== 0) {
            app.extraMenu.updateProductTotal(data, '.udu__td-bottom-subtotal .money', 'Y');
          }
        });
        $(this).remove();

        if ($('.extramenu-container .car-row').length === 0) {
          app.extraMenu.emptyCartMessage();
        }
      });
    } else {
      app.countDown.set($productDel, function () {
        $('.udu__td-bottom-subtotal .money').addClass('load');
        app.extraMenu.getURL('cart/remove-product', id);
        $productDel.remove();

        if ($('.div-cart-table').length > 0) {
          var $cartRow = $('[value=' + id + ']');
          $cartRow.parents('tr.car-row').fadeOut(function () {
            $(this).remove();
          });
        }

        if ($('.extramenu-container .car-row').length === 0) {
          app.extraMenu.emptyCartMessage();
        }

        app.events.trigger('udu-EXTRA-MENU-ITEM-REMOVED', {
          id: id
        });
      });
      return false;
    }
  },

  /**
   * Product quantity change
   * @param id {number} ID of the product to update the quantity
   */
  recalculateProduct: function recalculateProduct(id) {
    var $inputQnt = $('.product-id-' + id).find('[name=quantity]');
    var newQnt = Number($inputQnt.val());
    var maxlength = Number($inputQnt.attr('maxlength'));

    if (newQnt > maxlength) {
      var textError = app.lang.selectWord('We are sorry. The item\'s quantity currently available is ', 'Nous sommes désolés. La quantité de l\'article actuellement disponible est ');
      var htmlError = '<div class="extra-message-error error-id-' + id + '">' + textError + maxlength + '</div>';
      var messageError = '.error-id-' + id;
      var $checkMessage = $('div').is(messageError);

      if (!$checkMessage) {
        $inputQnt.closest('.item-controls').after(htmlError);
        $(messageError).slideDown("slow");
      } else {
        $(messageError).fadeOut(500).fadeIn(1000);
      }
    } else {
      app.extraMenu.deleteQntMessageError(id);
      $('.anim-id-' + id).addClass('spin');
      $('.extramenu-container .car-row').css({
        'pointer-events': 'none'
      });
      app.extraMenu.getURL('cart/update-quantity', id, newQnt);
    }

    return false;
  },

  /**
   * Empty cart message
   */
  emptyCartMessage: function emptyCartMessage() {
    var textEmpty = app.lang.selectWord('There are no products in the cart.', 'Il n\'y a pas d\'articles dans le panier.');
    var $htmlEmpty = "<div class=\"message info udu__empty-cart-message\">".concat(textEmpty, "</div>");
    var $cart = $('table.list');
    var $checkout2 = $('.udu_checkout');
    $cart.empty();

    if ($checkout2.length > 0) {
      $checkout2.empty();
      $checkout2.after($htmlEmpty);
    }

    if ($('.bg-payment').length > 0) {
      $('.bg-payment .div-border').empty().after($htmlEmpty);
    }

    $cart.after($htmlEmpty);
  },

  /**
   * Generic page refresh file after successful processing
   * @param data {string} Received ajax result
   * @param id {number} ID of the product to update the quantity
   */
  generalUpdate: function generalUpdate(data, id) {
    app.extraMenu.updateProductTotal(data, '.car-row .subtotal .money');
    app.extraMenu.updateProductTotal(data, '.car-row .unit-price .money');
    app.extraMenu.updateProductTotal(data, '.udu__td-bottom-subtotal .money', 'Y');
    app.extraMenu.updateQnt(data);

    if (id) {
      $('.anim-id-' + id).fadeOut().removeClass('spin').fadeIn();
      $('.extramenu-container .car-row').removeAttr('style');
    }

    app.cart.updateTopCart();
  },

  /**
   * Updating the product total (money)
   * @param data {string} Received ajax result
   * @param selector {string} Product total
   * @param trigger {string} Y/N
   */
  updateProductTotal: function updateProductTotal(data, selector, trigger) {
    var newdata = {};
    var countMax = 0,
        count = 0;
    $.each($($.parseHTML(data)).find(selector), function (key) {
      newdata[key] = $(this).text();
      countMax++;
    });
    $(selector).each(function () {
      if (count === countMax) count = 0;
      $(this).text(newdata[count]);
      count++;
    });

    if (trigger === 'Y') {
      app.events.trigger('udu-CART-IS-UPDATED', {
        subtotal: newdata[0]
      });
      $(selector).removeClass('load');
    }
  },

  /**
   * Updating the product quantity
   * @param data {string} Received ajax result
   */
  updateQnt: function updateQnt(data) {
    var newdata = {};
    var countMax = 0,
        count = 0;
    var $qntPlace = $('.udu__prod-quantity-place');
    $.each($($.parseHTML(data)).find('[name=quantity]'), function (key) {
      newdata[key] = Number($(this).val());
      countMax++;
    });
    $('[name=quantity]').each(function () {
      if (count === countMax) count = 0;
      $(this).attr('value', newdata[count]);
      count++;
    });

    if ($qntPlace.length > 0) {
      countMax = 0;
      count = 0;
      $qntPlace.each(function () {
        if (count === countMax) count = 0;
        $(this).text(newdata[count]);
        count++;
      });
    }
  },

  /**
   * Delete quantity error message, two cases: all messages and only for a product with an updated quantity
   * @param id {number}
   */
  deleteQntMessageError: function deleteQntMessageError(id) {
    if (id) {
      var messageError = '.error-id-' + id;
      $(messageError).fadeOut();
    } else {
      var $messageError = $('.extra-message-error');
      var $checkMessageAll = $('div').is($messageError);

      if ($checkMessageAll) {
        $messageError.remove();
      }
    }
  },

  /**
   * Data transfer from extra-menu.inc.tpl and loading on the page
   * @param callBack {function}
   */
  getInfo: function getInfo(callBack) {
    app.getInfo('get-extra-menu', function (info) {
      var $htmlMenu = $('.extra-menu').html(info);

      if ($htmlMenu && callBack) {
        setTimeout(function () {
          callBack($htmlMenu);
        }, 300);
        return false;
      }
    });
  },

  /**
   * Gets URL by action
   * @param act {string}
   * @param id {number}
   * @param newQnt {number}
   */
  getURL: function getURL(act, id, newQnt) {
    id = id || '';
    newQnt = newQnt || '';
    app.getURL(act, function (url) {
      $.post(url, {
        cartItemKey: id,
        quantity: newQnt
      }, function (data) {
        switch (act) {
          case 'cart/remove-product':
            app.extraMenu.generalUpdate(data);
            break;

          case 'cart':
            app.extraMenu.getInfo(function () {
              app.extraMenu.reinit();
            });
            break;

          default:
            app.extraMenu.generalUpdate(data, id);
        }
      });
    });
  }
};